<template>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import 'materialize-css/dist/css/materialize.css'
import 'materialize-css/dist/js/materialize.js'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  mounted() {
    
  },
}
</script>

<style>

</style>
