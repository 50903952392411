<template>
  <div>
    <nav>
      <div class="nav-wrapper">
        <a href="#!" class="brand-logo"><i class="material-icons">android</i>Logo</a>
        <ul class="right hide-on-med-and-down">
          <li><a class="white-text" href="#!"><i class="material-icons">search</i></a></li>
          <li><a class="white-text" href="#!"><i class="material-icons">view_module</i></a></li>
          <li><a class="white-text" href="#!"><i class="material-icons">refresh</i></a></li>
          <li><a class="white-text" href="#!"><i class="material-icons">more_vert</i></a></li>
        </ul>
      </div>
    </nav>
    <div class="row algo-padding">
      <h5> QA - Hoja de inspección</h5>
      <table class="table">
        <thead>
          <tr>
            <th>
              Nombre de operación: 
            </th>
            <th>
              Paso: <br/>
              SOLDADURA<br/>
            </th>
            <th>
              Shop order:
            </th>
            <th>
              Número de parte: 

            </th>
            <th>
              Revision:
            </th>
          </tr>
          <tr>
            <th>
              <div class="input-field"><input type="text" name="nom_ope" id="nom_ope"></div> 
            </th>
            <th>
              <div class="input-field"><input type="text" name="paso" id="paso"></div> 
            </th>
            <th>
              <div class="input-field"><input type="text" name="shop_order" id="shop_order"></div> 
            </th>
            <th>
              <div class="input-field"><input type="text" name="num_part" id="num_part"></div> 
            </th>
            <th>
              <div class="input-field"><input type="text" name="rev" id="rev"></div> 
            </th>
          </tr>
          <tr>
            <th @click="getModalFrecuencia()" rowspan="2">
              Freceuncia: <input type="number" name="freceuncia_val" value="0" id="">
            </th>
            <th>Unidad de medida</th>
            <th colspan="2">Simbologia</th>
            <th>Cliente</th>
          </tr>
          <tr>
              <th>
                <div class="input-field"><input type="text" name="unidad_medida" id="unidad_medida"></div> 
              </th>
              <th colspan="2"> 
                <div class="input-field"> <img :src="require('../assets/simbo.png')" alt="" srcset=""></div>
              </th>
              <th >
                <div class="input-field"><input type="text" name="cliente" id="cliente"></div> 
              </th>
          </tr>
        </thead>
      </table>
      <table class="border">
        <tr>
          <td class="title-row">HORA</td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
          <td><input type="time" name="" id=""></td>
        </tr>
        <tr>
          <td class="title-row">Fecha <br/> (DD/MM/AA)</td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
          <td><input type="date" name="" id=""></td>
        </tr>
        <tr>
          <td class="title-row"># DE EMPLEADO</td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          <td><label contenteditable="true"> example </label></td>
          
        </tr>
      </table>

      <table class="table-qua">
        <thead >
          <tr>
            <td>Medidas Variables o Atributos/Tolerancias Primera Pieza (Produccion)</td>
            <td>Validacion 1ra pieza (QA)</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>pieza</td>
            <td>Ultima pieza (QA)</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="index in 15" :key="index">
            <td v-for="$i in 13" :key="$i" contenteditable="true">
              
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="aceptado-row">
            <td>Aceptado</td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
            <td contenteditable="true" ></td>
          </tr>
          <tr class="rechazado-row">
            <td>Rechazado</td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
            <td contenteditable="true"></td>
          </tr>
        </tfoot>
      </table>
    </div>
      <div id="ModalFrecuencia" class="modal">
        <div class="modal-content">
        <div class="container">
          <table class="table">
            <tr>
              <th colspan="3">
                <div class="text-title" style="text-align:center">
                  <h3>Tabla de muestreo</h3>
                  <code>QAFR_0017.4.MX  Anexo A</code>
                </div>
              </th>
            </tr>
            <tr class="hader-table">
              <td>Tamaño de lote</td>
              <td>Tamaño de muestra "S" (Corte, Doblez, PEM y Ensamble)</td>
              <td>Tamaño de muestra "H" (prensas)</td>
            </tr>

            <tbody>
              <tr>
                <td>2 a 15</td>
                <td>2</td>
                <td rowspan="2">3</td>
              </tr>
              <tr>
                <td>16 a 25</td>
                <td>3</td>
              </tr>
              <tr>
                <td>26 a 90</td>
                <td>5</td>
                <td>5</td>
              </tr>
              <tr>
                <td>91 a 150</td>
                <td>8</td>
                <td>7</td>
              </tr>
              <tr>
                <td>151 a 280</td>
                <td>13</td>
                <td>10</td>
              </tr>
              <tr>
                <td>281 a 500</td>
                <td>20</td>
                <td>11</td>
              </tr>
              <tr>
                <td>501 a 1200</td>
                <td>32</td>
                <td>15</td>
              </tr>
              <tr>
                <td>1201 a 3200</td>
                <td>50</td>
                <td>18</td>
              </tr>
              <tr>
                <td>3201 a 10 000 </td>
                <td>80</td>
                <td>22</td>
              </tr>
              <tr>
                <td>10 001 a 35 000</td>
                <td>125</td>
                <td>29</td>
              </tr>
              <tr>
                <td>35 001 a 150 000</td>
                <td>200</td>
                <td>29</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" style="text-align:center">
                  <code>  Para calcular la FRECUENCIA usar la fórmula: </code> <br>
                  <h4>Tamaño de lote ÷ Tamaño de muestra</h4>
                  <div class="row">
                    <div class="input-fields col s5">
                      <input class="" type="text" name="valorA" id="valorA">
                    </div>
                    <div class="input-fields col s2">
                      <h3>
                        ÷
                      </h3>
                    </div>
                    <div class="input-fields col s5">
                      <input class="" type="text" name="valorB" id="valorB">
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="text-align:center">
                  <i>
                    NOTA: Para el calculo de la frecuencia usar las cantidades designadas en: 
                    TAG: Doblez, pem, soldadura, pintura 
                    Shop Order: Corte, Prensas, Ensamble
                  </i>
                </td>
              </tr>
            </tfoot>
          </table>
          
        </div>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Guardar</a>
      </div>
    </div>

    <!-- <button class="waves-effect waves-light btn modal-trigger" href="#ModalFrecuencia"> Open Modal </button> -->
  </div>
</template>

<script>
// const modal = require("vue-materialize/modal")
import M from 'materialize-css'
// import vuetable from './vuetable'
// import Vuetable from './vuetable.vue';
// import VueTableDynamic from 'vue-table-dynamic'
export default {
  name: 'QA',
  components:{
    
    // VueTableDynamic
    // "modal": require("vue-materialize/modal"),
  },
  props: {
    msg: String
  },
  methods:{
    getModalFrecuencia(){
      console.warn("Soy un modal");
      let _elems = document.querySelector('#ModalFrecuencia');
      this.instances = M.Modal.init(_elems, {});      
      this.instances.open()
    },
  },
  mounted() {
    // document.addEventListener('DOMContentLoaded', function() {
      
      
      
    // });
  },
  data() {
    return {
      instances:{},
      params: {
        data: [
          ['Cell-1', 'Cell-2', 'Cell-3'],
          ['Cell-4', 'Cell-5', 'Cell-6'],
          ['Cell-7', 'Cell-8', 'Cell-9']
        ]
      },
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table.table-qua td{width: 8%; border:solid #000 1px;max-width: 12px;}
  table.table-qua > thead > tr > td { font-weight:600 ;text-align: center;}
  .brand-logo{margin-left:15px ;}
  th,td{padding: 0px 5px;}
  .hader-table td{width: 220px; height: 80px;}
  .title-row{font-size: 1.2em;font-weight: bolder;width: 130px;}
  .border td{
    border:1px solid gray;
    height: 65px;
    max-width: 124px;
  }
  .algo-padding{
    padding-left: 50px;
    padding-right: 50px;
  }
</style>
